import React from "react";
//import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  //navBar,
  mainBody,
  
} from "./editable-stuff/config.js";
import MainBody from "./components/home/MainBody";

class App extends React.Component {
  render = () => {
    return (   
      <MainBody
        gradient={mainBody.gradientColors}
        subtitle={mainBody.subtitle}
        title={`${mainBody.firstName} ${mainBody.middleName} ${mainBody.lastName}`}
        message={mainBody.message}
        icons={mainBody.icons}
        
      />
      
      

      
    
  );
};
}

export default App;
