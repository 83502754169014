// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  subtitle: "Kanda",
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Coming Soon",
  middleName: "",
  lastName: "",
  message: " A modern multi-asset digital custody platform. ",
  icons: [
    {
      image: "fa-github",
      url: "https://www.kandat.io/",
    },
    {
      image: "fa-facebook",
      url: "https://www.kandat.io/",
    },
    {
      image: "fa-instagram",
      url: "https://www.kandat.io/",
    },
    {
      image: "fa-linkedin",
      url: "https://www.kandat.io/",
    },
    {
      image: "fa-twitter",
      url: "https://www.kandat.io/",
    },
  ],
};




export { navBar, mainBody };
